.setting-modal-style .ant-modal-body {
    background: #0e0e0e !important;
}

.setting-modal-style .ant-modal-footer {
    background: #0e0e0e !important;
}

.setting-modal-title-style {
    width: 100% !important;
    text-align: center;
    font-size: 20px !important;
    color: antiquewhite !important;
}

.setting-modal-add-email-style {
    color: antiquewhite !important;
    margin-top: 20px !important;
}

.setting-modal-email-list-style {
    color: antiquewhite !important;
    margin-top: 5px !important;
    text-align: center !important;
}

.setting-modal-style .ant-switch {
    width: 50px !important;
    background-color: transparent !important;
}

.setting-modal-style .ant-switch-checked {
    background-color: green !important;
}