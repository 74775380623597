.machine-setting-page-title {
    color: #dbdbdb;
    text-decoration: underline;
    cursor: pointer;
}
.machine-setting-page-title:hover{
    color: #ffffff;
}
.machine-setting-page-title:active{
    color: #c2c2c2;
}

.machine-setting-machine-table-style{
    margin-left: 50px !important;
}