.sensor-alert-setting-container {
  margin-top: 20px;
  font-size: 18px;
}

/* .sensor-alert-setting-update-button {
    background-color: #7f77f5;
    border: 1px sold #5a5a5a;
    height: 50px;
}
.sensor-alert-setting-update-button:hover{
    background-color: #b1adf5;
}
.sensor-alert-setting-update-button:active{
    background-color: #7a78a5;
} */
.sensor-alert-setting-limit {
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
}
.sensor-alert-setting-limit-title {
  font-weight: normal;
}
.sensor-alert-setting-update-button {
  text-align: center;
  /* width: 100%; */
  font-size: 20px !important;
  height: 40px !important;
}
.sensor-alert-setting-button-container {
  margin-left: 20px;
}
