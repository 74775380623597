.customer-setting-page-title {
    color: #dbdbdb;
    text-decoration: underline;
    cursor: pointer;
}
.customer-setting-page-title:hover{
    color: #ffffff;
}
.customer-setting-page-title:active{
    color: #c2c2c2;
}

.customer-setting-container-style {
    margin-left: 50px;
    display: inline-block !important;
}

.customer-setting-update-button {
    /* margin-left: 50px !important; */
    font-size: 40px !important;
    font-weight: bold;
    color: green;

}

.customer-setting-update-button:hover {
    color: rgb(4, 182, 4);
}

.customer-setting-update-button:active {
    color: rgb(2, 75, 2);
}