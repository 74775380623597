.app-setting-option-input-container {
    margin-top: 10px;
}

.app-setting-option {
    width: 100% !important;

}

.app-setting-option>* .ant-radio-checked>.ant-radio-inner {
    border-color: transparent;
}