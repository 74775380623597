.one-sensor-container {
    background: linear-gradient(#333333, #222222);
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 10px 10px 5px #111111;
    padding: 10px;

    min-height: 320px;
}

.one-sensor-container:hover {
    background: linear-gradient(#333333, #222222);
    cursor: pointer;
    box-shadow: 0px 0px 10px #21a300;
}

.one-sensor-container:active {
    background: linear-gradient(#333333, #222222);
}

.one-sensor-name {
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #eeeeee;
}

.one-sensor-image-style {
    height: 100px;
}

.one-sensor-image-container-style {
    text-align: center !important;
    /* background-color: white; */
}

.one-sensor-status {
    font-size: 40px;
}

.one-sensor-delete-button {
    float: right;
    color: #999999;
    font-size: 20px;
    font-weight: bold;
}

.one-sensor-delete-button:hover {
    color: white;
}
.one-sensor-delete-button:active {
    color: rgb(104, 102, 102);
}

.sensor-delete-popup{
    
}