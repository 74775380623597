.info-container {
    border: 1px solid #8A8A8A !important;
    border-radius: 2px !important;
    background: #404040 !important;
    padding: 5px !important;
    margin: 5px !important;
}

.user-machine-info-container-mobile {
    height: 100px;
}

.operator-name-style {
    color: #eeeeee;
    text-align: left;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;

    float: left;
}

.user-name-container {
    /* display: flex;
    flex-direction: row;
    width: 50%; */
    float: center;
}

.app-version-container {
    /* display: flex;
    flex-direction: row;
    width: 50%; */
    float: right;
}

.app-version-text {
    color: #eeeeee;
    text-align: left;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
}

/* style={{ display: 'flex', flexDirection: 'row', width: '100%' }} */
.detail-operator-image-style {
    margin-top: 5px;
    float: left;
    border-radius: 50%;
    box-shadow: 0px 0px 5px white;
    object-fit: cover;
}

.machine-image-container {
    text-align: center !important;

}

.machine-image-container-mobile img {
    max-width: 100%;
    height: 90px !important;
}

.intro-video-container {
    height: 150px !important;
    background: #0e0e0e !important;
    padding-top: 20px;
}

.indicator-container {
    height: 318px !important;
    padding: 20px !important;

}

.machine-detail-container {
    background: transparent;
    margin: 0px;
    text-align: center !important;
    /* style={{ width: 1200, textAlign:'center' }} */
}

.detail-content-container {}

.utilization-container {
    padding-top: 0px !important;
}

.timeline-container {
    height: 213px !important;
}

.history-container {
    height: 213px !important;
}

.history-item {
    color: #eeeeee !important;
    padding: 0px !important;
    margin: 0px !important;
}

.history-item:hover {
    cursor: pointer;
    background: #8A8A8A !important;
}

.history-item:active {
    cursor: pointer;
    background: rgb(104, 104, 104) !important;
}

.history-item-selected {
    background-color: #1e1e1e !important;
    color: #ffffff !important;

}

.content-table {
    /* width: 1400px !important; */
}

.ant-progress-text {
    color: #eeeeee !important;
    font-weight: bold !important;
}

.ant-progress-circle>path {
    stroke-width: 20 !important
}

.see-detail-gantt-modal {
    color: #36A3F0 !important;
    cursor: pointer;
}

.see-detail-gantt-modal:hover {
    color: rgb(94, 185, 250) !important;
}


.detailed-line-timeline text {
    color: #eeeeee !important;
    fill: #eeeeee !important;
}

.detailed-line-timeline svg g:first-of-type rect {
    fill-opacity: 0;
}

.detail-gantt-modal {
    background: #1e1e1e !important;
    background-color: #1e1e1e !important;
}

.detail-gantt-modal .ant-modal-body {
    background: #1e1e1e !important;
    background-color: #1e1e1e !important;
    border: 2px solid white !important;
}

.detailed-chart-container {
    width: 100% !important;
    height: 100% !important;
}

.detail-gantt-modal .ant-modal-close-x {
    display: none !important;
}

.backward-button {
    color: #eeeeee !important;
    font-size: 30px !important;
    font-weight: bold !important;
}

.backward-button :hover {
    color: #f5dbdb !important;
    border: 1px solid #1e1e1e !important;
}

.app-setting-button {
    color: #eeeeee !important;
    font-size: 30px !important;
    font-weight: bold !important;
    margin: 2%;
}

.app-setting-show_cycle_help {
    color: #eeeeee !important;
    font-size: 30px !important;
    font-weight: bold !important;
    margin: 2%;
}

.app-setting-button:hover {
    color: #f5dbdb !important;
    border: 1px solid #1e1e1e !important;
}

.sendEmail {
    font-size: 30px !important;
    color: grey !important;
    rotate: 10 !important;
    transform: rotate(-20deg);
}

.sendEmail:hover {
    color: white !important;
    transform: rotate(-45deg);
}

.sendEmail:active {
    color: red !important;
    transform: rotate(-45deg);
}

.sendEmailContainer {
    text-align: right !important;
    padding-right: 50px !important;
}

.dateContainer {
    text-align: right !important;
}

.confirm-dialog>.ant-modal-content {
    background-color: #0e0e0e !important;
}

.confirm-dialog>* .ant-modal-confirm-title {
    color: #eeeeee !important;
}

.confirm-dialog>* .ant-modal-confirm-content {
    color: #eeeeee !important;
    font-size: 20px !important;
    text-align: center;
}

.confirm-dialog>* .ant-btn-primary {
    color: green !important;
    border-color: #0e0e0e !important;
}

.confirm-dialog>* .ant-btn-primary:hover {
    color: darkgreen !important;
    border-color: green !important;
}

.confirm-dialog>* .ant-btn {
    color: red;
    border-color: #0e0e0e;
    background-color: #0e0e0e !important;
}

.confirm-dialog>* .ant-btn:hover {
    color: rgb(243, 75, 75);
    border-color: #0e0e0e;
    background-color: #0e0e0e !important;
}