.user-setting-page-title {
    color: #dbdbdb;
    text-decoration: underline;
    cursor: pointer;
}
.user-setting-page-title:hover{
    color: #ffffff;
}
.user-setting-page-title:active{
    color: #c2c2c2;
}

.user-setting-user-table-style{
    margin-left: 50px !important;
}