.reportemails-title-style {
    color: #eeeeee !important;
    display: inline-block;
    margin-right: 10px !important;
}

.reportemails-mail-input-style {
    display: inline-block;
    margin-right: 10px !important;
}
.reportemails-mail-add-button-style {
    display: inline-block;
    margin-right: 20px !important;
}

.reportemails-input {
    background-color: transparent !important;
    color: #eeeeee !important;
    width: 200px !important;
}

.reportemails-maillist-container-style{
    margin-left: 150px !important;
    margin-bottom: 50px;
}