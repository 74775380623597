.detail-page-container-style {
  color: #eeeeee !important;
  margin-right: 10px;
  padding-bottom: 50px;
}

.detail-page-title {
  font-weight: bold;
  font-size: 24px;
}

.detail-page-trend-container {
  margin-top: 20px;
}

.detail-page-trend-title {
  font-weight: bold;
  font-size: 16px;
}

.detail-page-trend-chart {
  background-color: transparent !important;
}

.detail-page-date-container {
  /* margin-top: 30px; */
}

.detail-page-one-date-container {
}

.detail-page-export-button-container {
}

.detail-page-export-button {
  background-color: #3b088d;
  border: 1px sold #5a5a5a;
  width: 150px;
  height: 50px;
}

.detail-page-export-button:hover {
  background-color: #353535;
  cursor: pointer;
}

.detail-page-one-date {
}

.detail-page-container-info-container {
}

.sensor-backward-button {
  color: #eeeeee !important;
  font-size: 30px !important;
  font-weight: bold !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sensor-backward-button :hover {
  color: #f5dbdb !important;
  /* border: 1px solid #1e1e1e !important; */
}

.sensor-detail-one-sensor-name {
  margin-top: 5px;
}

.sensor-detail-one-sensor-location {
  margin-top: 5px;
}

.sensor-detail-one-sensor-info-title {
  font-size: 18px;
  display: inline-block;
  width: 100px;
}

.sensor-detail-one-sensor-info-value {
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
}

.sensor-detail-page-image-style {
  max-height: 100px;
  padding-right: 30px;
  /* 
    position: relative;
    top: 100%;
    left: 50%;
    -ms-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%); */
}

.detail-page-container-image-container {
  /* display: inline-block; */
  text-align: center;
}

.detail-page-container-info {
  /* display: inline-block; */
}

.sensor-detail-one-sensor-status-title {
  display: inline-block;
}

.sensor-detail-one-sensor-status-value {
  margin-left: 10px;
  font-weight: bold;
  display: inline-block;
}

.flex-container {
  padding: 0;
  margin: 0;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.nowrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.chart-description-container {
  margin-top: 5px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #b8b8b8;
  background: linear-gradient(90deg, #414040 0%, #1e1e1e 100%) !important;
}
