.background{
    background-color: #1E1E1E;
    /* width: 100vh; */
    height: 100vh;
    margin: 0;
    padding: 0;
    text-align: center !important;
}
div.LoginDialog{

    width: 400px ;
    height: 550px ;

    background-image: linear-gradient(#333333,#222222 );
    margin-top: 7% ;
    display: inline-block;
    box-shadow: 10px 10px 5px #111111;
    border: 2px solid #444444 !important;
    
    padding: 30px;
    padding-top: 50px;
    text-align: center;
}
.whiteText{
    color: #cccccc;
}
.login-dlg-button{
    border-radius: 20px !important;
    height: 40px !important;
    padding: 0px 50px !important;
}
.login-page-logo-container{
    text-align: center;
    margin-bottom: 30px;
}
.login-page-logo-image{
    width: 200px !important;
    margin-bottom: 20px;
}
.login-page-title{
    color: #cccccc;
    line-height: 12px;
}
.login-page-link-text{
    color: white;
    margin-top: 30px;
}