.pdf-util-line-chart-style{
    display: inline-block;
    margin-left: 80px;
}

/* svg {
    margin-left: 30px;
} */


