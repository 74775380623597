
.add-sensor-button-container {
    background: linear-gradient(#333333, #222222);
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 10px 10px 5px #111111;
    padding: 10px;
    height: 320px;
    text-align: center;
}

.add-sensor-button-container:hover {
    background: linear-gradient(#333333, #222222);
    cursor: pointer;
    box-shadow: 0px 0px 10px #6793f1;
}

.add-sensor-button-container:active {
    background: linear-gradient(#252525, #222222, #252525);
}