.simple-info-container{
    color: #eeeeee;
    
    width: 100% !important;
}
.simple-info-container-title{
    font-weight: bold;
    font-size: 14px;
    display: inline-block !important;
    /* float: left; */
}
.simple-info-container-value{
    margin-left: 15px;
    font-size: 14px;
    display: inline-block !important;
    /* float: left; */
}