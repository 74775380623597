.one-email-container-style {
    margin-right: 30px;
    border: 1px solid green;
    display: inline-block;
    background-color: green;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
}


.one-email-style {
    font-weight: bold;
    color: #eeeeee;
    margin-right: 30px;
}


.one-email-delete-style {
    color: #ff0000;
    cursor: pointer;
}

.one-email-delete-style:hover {
    font-weight: bold;
    color: #fd2f2f;
}

.one-email-delete-style:active {
    color: #ce0303;
}